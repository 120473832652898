<template>
	<div>
		<pui-field-set :title="$t('' + this.formEditionableTitle + '')">
			<v-row>
				<v-col class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1" v-if="!this.disableEditing">
					<v-btn icon class="elevation-0 pui-toolbar__actionBtn primary white--text" :title="$t('puigridcreate.title')" @click="openDialog">
						<v-icon>far fa-plus-circle</v-icon>
					</v-btn>
				</v-col>
				<v-col
					class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
					v-if="!this.disableEditing"
					:style="{
						marginLeft: $vuetify.breakpoint.smAndUp ? '3%' : $vuetify.breakpoint.mdAndUp ? '3%' : ''
					}"
				>
					<v-btn
						class="elevation-0 pui-toolbar__actionBtn white--text secondary v-btn v-btn--icon v-btn--round theme--light v-size--default pui-toolbar__actionBtn--desktop"
						:id="`gridbtn-delete-editionabletable`"
						@click="openDialogRemove"
						style="margin-left: -120%"
						><v-icon>far fa-trash-alt</v-icon></v-btn
					>
					<v-menu offset-y>
						<template v-slot:activator="{ on }">
							<v-btn
								variant="tonal"
								class="elevation-0 pui-toolbar__actionBtn pui-toolbar__multiBtn white--text v-btn--round theme--light pui-toolbar__btn pui-toolbar__superMiniBtn ml-1"
								v-on="on"
							>
								<v-icon class="pa-2">far fa-file-export</v-icon>
								<v-icon class="pa-2">far fa-angle-down</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item @click="importar()">
								<v-icon size="large" variant="tonal" class="elevation-0 pui-toolbar__superMiniBtn mr-1">
									fa-kit fa-line-export-excel
								</v-icon>
								<v-list-item-title>Import CSV</v-list-item-title>
							</v-list-item>
							<!--<v-list-item @click="exportar()">
								<v-icon size="large" variant="tonal" class="elevation-0 pui-toolbar__superMiniBtn mr-1"> far fa-file-export </v-icon>
								<v-list-item-title>Export</v-list-item-title>
							</v-list-item>-->
						</v-list>
					</v-menu>
				</v-col>
				<v-col class="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10"></v-col>
			</v-row>
			<v-dialog v-model="dialogVisible" max-width="1200">
				<v-card>
					<v-card-title> {{ $t('' + this.formEditionableTitle + '') }} </v-card-title>
					<v-card-text>
						<v-form ref="form" lazy-validation @submit.prevent="saveData">
							<component :is="formComponent" :filterValue="filterValue" :filterColumn="filterColumn"></component>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-row>
							<v-col class="col-1 col-xs-1 col-sm-1 col-md-8 col-lg-8 col-xl-8"></v-col>
							<v-col class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" v-if="!this.disableEditing">
								<v-btn width="100px" color="primary" style="color: #fff !important" @click="submitForm"
									>{{ $t('nsweditionabletable.save') }}
								</v-btn>
							</v-col>
							<v-col class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" v-if="!this.disableEditing">
								<v-btn width="100px" color="secondary" style="color: #fff !important" @click="closeDialog">{{
									$t('nsweditionabletable.cancel')
								}}</v-btn>
							</v-col>
							<v-col class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" v-if="this.disableEditing" style="margin-left: 160px">
								<v-btn width="100px" color="primary" style="color: #fff !important" @click="closeDialog">
									{{ $t('nsweditionabletable.close') }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="dialogDeleteAlertVisible" max-width="600">
				<v-card>
					<v-card-title class="font-weight-bold">
						{{ $t('nsweditionabletable.removedialogtitle') }}
					</v-card-title>
					<v-card-text>
						{{ $t('nsweditionabletable.removedialog') }}
					</v-card-text>
					<v-card-actions>
						<v-col cols="7"></v-col>
						<v-col v-if="!disableEditing" cols="2" class="mr-4">
							<v-btn width="100px" color="lightray" style="color: black !important" @click="closeRemoveDialog">
								{{ $t('nsweditionabletable.cancel') }}
							</v-btn>
						</v-col>
						<v-col v-if="!disableEditing" cols="2">
							<v-btn width="100px" color="primary" style="color: #fff !important" @click="deleteSelectedRow">
								{{ $t('nsweditionabletable.save') }}
							</v-btn>
						</v-col>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<table class="cell-border hover stripe nowrap full-width dataTable no-footer">
				<thead>
					<tr>
						<th v-for="column in myColumns" :key="column">{{ $t('' + formComponent + '.' + column + '') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, index) in this.visibleItems"
						:key="index"
						@click="selectRow(index)"
						@dblclick="openEditDialog(index)"
						:class="{ 'nsw-editionable-selected-row': index === selectedRow }"
					>
						<td v-for="column in myColumns" :key="column">
							{{
								column === 'location' && typeof item[column] === 'string'
									? formatLocation(item[column])
									: column.includes('date')
									? formatDatetime(item[column])
									: item[column]
							}}
						</td>
						<!--{{ column.includes('date') ? formatDatetime(item[column]) : item[columna] }}-->
					</tr>
				</tbody>
			</table>
			<br />
			<!--	<div class="pagination">
				<v-btn class="elevation-0 pui-toolbar__actionBtn white--text primary" @click="prevPage" :disabled="currentPage === 1">
					{{ $t('nsweditionabletable.previous') }}</v-btn
				>
				<span class="nsw-editionable-page-count">{{ currentPage }}</span>
				<v-btn class="elevation-0 pui-toolbar__actionBtn white--text primary" @click="nextPage" :disabled="currentPage === totalPages">
					{{ $t('nsweditionabletable.next') }}</v-btn
				>
			</div>-->
		</pui-field-set>
		<NswImportDialog :openDialog="dialogImport" @closedialog="closeImport()" @newData="importData"> </NswImportDialog>
	</div>
</template>

<script>
import NswImportDialog from './NswImportDialog.vue';

export default {
	name: 'nsw-editionable-table',
	comments: { NswImportDialog: NswImportDialog },
	props: {
		formComponent: {
			type: String,
			required: true
		},
		tableData: {
			type: Array,
			required: true
		},
		myColumns: {
			type: Array,
			required: false
		},
		itemsPerPage: {
			type: Number,
			required: false,
			default: 10
		},
		disableEditing: {
			type: [Boolean, String],
			default: false
		},
		filterValue: {
			type: String,
			required: false
		},
		filterColumn: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			formData: {},
			selectedRow: -1,
			currentPage: 1,
			visibleItems: [],
			dialogVisible: false,
			formEditionableTitle: '',
			dialogDeleteAlertVisible: false,
			dialogImport: false
		};
	},
	mounted() {
		document.addEventListener('keydown', this.handleEscKey);
		this.$puiEvents.$on('form-datatransfer-' + this.formComponent, (formData) => {
			this.formData = formData;
			this.saveData();
		});

		this.setVisibleItems();
		this.formEditionableTitle = this.formComponent + '.title';
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.handleEscKey);
	},
	destroyed() {
		this.$puiEvents.$off('form-datatransfer-' + this.formComponent);
		document.removeEventListener('keydown', this.handleEscKey);
	},
	computed: {
		totalPages() {
			return Math.ceil(this.tableData.length / this.itemsPerPage);
		}
	},
	methods: {
		saveData() {
			if (!this.disableEditing && this.$refs.form.validate()) {
				if (this.selectedRow === -1) {
					this.tableData.push({ ...this.formData });
				} else {
					this.tableData[this.selectedRow] = { ...this.formData };
					this.selectedRow = -1;
				}
				this.setVisibleItems();
				this.dialogVisible = false;
			} else if (this.disableEditing) {
				// Aquí puedes manejar el caso cuando la edición está deshabilitada
				this.showEditingDisabledNotification();
			} else {
				this.$puiNotify.error(this.$t('nsweditionabletable.formerror'), this.formComponent);
			}
		},
		selectRow(index) {
			if (index === this.selectedRow) {
				this.selectedRow = -1;
				this.formData = {};
			} else {
				this.selectedRow = index;
				this.formData = { ...this.tableData[index] };
			}
		},
		openDialogRemove() {
			if (!this.disableEditing) {
				this.dialogDeleteAlertVisible = true;
			}
		},
		deleteSelectedRow() {
			if (!this.disableEditing && this.selectedRow !== -1) {
				this.tableData.splice(this.selectedRow, 1);
				this.selectedRow = -1;
				this.setVisibleItems();
				this.formData = {};
				this.dialogDeleteAlertVisible = false;
			}
		},
		prevPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.setVisibleItems();
			}
		},
		nextPage() {
			if (this.currentPage < this.totalPages) {
				this.currentPage++;
				this.setVisibleItems();
			}
		},
		submitForm() {
			this.$puiEvents.$emit('form-submitted-' + this.formComponent);
		},
		setVisibleItems() {
			const startIndex = (this.currentPage - 1) * this.itemsPerPage;
			const endIndex = startIndex + this.itemsPerPage;
			if (this.filterColumn && this.filterValue) {
				this.visibleItems = this.tableData.slice(startIndex, endIndex).filter((element) => element[this.filterColumn] == this.filterValue);
			} else {
				this.visibleItems = this.tableData.slice(startIndex, endIndex);
			}
		},
		openEditDialog(index) {
			this.selectedRow = index;
			this.formData = { ...this.tableData[index] };
			this.$puiEvents.$emit('form-loadinfo-update-' + this.formComponent, this.formData);
			this.dialogVisible = true;
			this.$store.commit('setlastcallsform', this.formData);
			this.$store.commit('setshipactivitiesform', this.formData);
			this.$store.commit('setpersonslistform', this.formData);
			this.$store.commit('setpreviouscallsform', this.formData);
			this.formData = {};
		},
		openDialog() {
			this.formData = {};
			if (!this.disableEditing) {
				this.$puiEvents.$emit('form-openinsert-' + this.formComponent, this.formData);
				this.dialogVisible = true;
				this.formData = {};
			}
		},
		closeDialog() {
			this.$puiEvents.$emit('form-cancel-' + this.formComponent);
			this.$puiEvents.$emit('form-unloadinfo-' + this.formComponent, {});
			this.dialogVisible = false;
			this.formData = {};
		},
		closeRemoveDialog() {
			this.dialogDeleteAlertVisible = false;
		},
		formatDatetime(dateString) {
			if (!dateString) return ''; // Manejo para fechas nulas o indefinidas
			return this.$moment(dateString).utc().format('DD-MM-YYYY');
		},
		showEditingDisabledNotification() {
			this.$puiNotify.warning('This form is not a draft and cannot be modified.', this.formComponent);
		},
		handleEscKey(event) {
			event.stopPropagation();

			// Verificar si la tecla presionada es "Esc"
			if (event.key === 'Escape') {
				this.closeDialog();
				event.preventDefault();
			}
		},
		formatLocation(location) {
			// Verificar si el formato es el esperado
			//const regex = /^\d+\.\d+ \/ \d+\.\d+$/;
			const regex = /^-?\d+(\.\d{1,3})? \/ -?\d+(\.\d{1,3})?$/;
			if (regex.test(location)) {
				// Dividir las coordenadas
				const [lat, lon] = location.split(' / ');
				// Formatear y reemplazar el punto por la coma
				const formattedLocation = `${parseFloat(lat).toFixed(3).replace('.', ',')}° / ${parseFloat(lon).toFixed(3).replace('.', ',')}°`;
				return formattedLocation;
			} else {
				// Si el formato no es el esperado, devolver la ubicación sin cambios
				return location;
			}
		},
		importar() {
			this.dialogImport = true;
		},
		exportar() {
			var csv = '';

			// Encabezados
			var encabezados = Object.keys(this.tableData[0]);
			csv += encabezados.join(';') + '\n';

			// Filas de datos
			this.tableData.forEach(function (item) {
				var fila = encabezados.map(function (header) {
					return item[header];
				});
				csv += fila.join(';') + '\n';
			});

			var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
			var enlace = document.createElement('a');
			enlace.href = URL.createObjectURL(blob);
			enlace.target = '_blank';
			enlace.download = this.formComponent.replace('sheet', '') + '.csv';
			enlace.click();
		},
		closeImport() {
			this.dialogImport = false;
		},
		async importData(registries) {
			let country = '';
			let port = '';
			let nuevoObjeto = {};
			for (let index = 0; index < registries.length; index++) {
				const registrie = registries[index];
				for (const clave in registrie) {
					const nuevaClave = clave.replace(/_/g, ''); // Utiliza expresión regular para quitar todos los guiones bajos
					if (nuevaClave.includes('date')) {
						var dateString = registrie[clave];
						var dateParts = dateString.split(/[\s/:]+/);
						const originalDateTime = new Date(dateParts[2], dateParts[1] - 1, dateParts[0], dateParts[3], dateParts[4]);
						const newDateTime = new Date(originalDateTime.getTime() + 3 * 60 * 60 * 1000);
						registrie[clave] = newDateTime;
					}

					//CODE FOR mdhpreviouscalls
					if (nuevaClave == 'previouscallcountry') {
						country = registrie[clave];
					}
					if (nuevaClave == 'previouscallport') {
						port = registrie[clave];
					}
					if (country && port) {
						const opts = {
							model: 'mports',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'countrycode',
										op: 'eq',
										data: country
									},
									{
										field: 'portcode',
										op: 'eq',
										data: port
									}
								]
							}
						};

						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['port'] = response.data.data[0].locode + ' - ' + response.data.data[0].portdescription;
								nuevoObjeto['countrydesc'] = response.data.data[0].countrydesc;
							}
						});
						country = '';
						port = '';
					}

					if (nuevaClave == 'countrycode') {
						country = registrie[clave];
					}
					if (nuevaClave == 'portcode') {
						port = registrie[clave];
					}
					if (country && port) {
						const opts = {
							model: 'mports',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'countrycode',
										op: 'eq',
										data: country
									},
									{
										field: 'portcode',
										op: 'eq',
										data: port
									}
								]
							}
						};

						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['countrydesc'] = response.data.data[0].countrydesc;
								nuevoObjeto['portdescription'] = response.data.data[0].portdescription;
							}
						});
						country = '';
						port = '';
					}

					if (nuevaClave == 'embarkationcountry') {
						country = registrie[clave];
					}
					if (nuevaClave == 'embarkationport') {
						port = registrie[clave];
					}
					if (country && port) {
						const opts = {
							model: 'mports',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'countrycode',
										op: 'eq',
										data: country
									},
									{
										field: 'portcode',
										op: 'eq',
										data: port
									}
								]
							}
						};

						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['embarkation'] = response.data.data[0].locode + ' - ' + response.data.data[0].portdescription;
							}
						});
						country = '';
						port = '';
					}

					if (nuevaClave == 'shipactivitycountry') {
						country = registrie[clave];
					}
					if (nuevaClave == 'shipactivityport') {
						port = registrie[clave];
					}
					if (country && port) {
						const opts = {
							model: 'mports',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'countrycode',
										op: 'eq',
										data: country
									},
									{
										field: 'portcode',
										op: 'eq',
										data: port
									}
								]
							}
						};

						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								if (registrie['ship_activity_location_ind'] == 1) {
									nuevoObjeto['location'] = response.data.data[0].locode + ' - ' + response.data.data[0].portdescription;
								}
							}
						});
						country = '';
						port = '';
					}

					//CODE FOR mdhpreviouscalls
					if (nuevaClave == 'securitymeasurecode') {
						const opts = {
							model: 'vlupmsecuritymeasures',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'securitymeasurecode',
										op: 'eq',
										data: registrie[clave]
									}
								]
							}
						};
						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['measuredescription'] = response.data.data[0].measuredescription;
							}
						});
					}
					if (nuevaClave == 'portcallpurposecode') {
						const opts = {
							model: 'mportcallpurposes',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'portcallpurposecode',
										op: 'eq',
										data: registrie[clave]
									}
								]
							}
						};
						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['purposedesc'] = response.data.data[0].purposedesc;
							}
						});
					}
					if (nuevaClave == 'shipactivitylocationind') {
						switch (registrie[clave]) {
							case '2':
								nuevoObjeto['location'] = registrie['ship_activity_location_name'];
								break;
							case '3':
								nuevoObjeto['location'] =
									registrie['ship_activity_location_latitude'] + 'º/' + registrie['ship_activity_location_longitude'] + 'º';
								break;
							default:
								break;
						}
					}

					//CODE FOR securitylastcalls
					if (nuevaClave == 'securitylevelcode') {
						const opts = {
							model: 'vlupmsecuritylevels',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'securitylevelcode',
										op: 'eq',
										data: registrie[clave]
									}
								]
							}
						};
						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['securitylevelcode'] = response.data.data[0].securityleveldescription;
							}
						});
					}
					if (nuevaClave == 'portfacilitygisiscode') {
						registrie[clave];
						const opts = {
							model: 'vlupmportfacilities',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'portfacilitygisiscode',
										op: 'eq',
										data: registrie[clave]
									},
									{
										field: 'countrycode',
										op: 'eq',
										data: registrie['country_code']
									},
									{
										field: 'portcode',
										op: 'eq',
										data: registrie['port_code']
									}
								]
							}
						};
						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['portfacilityname'] =
									response.data.data[0].countrycode +
									response.data.data[0].portcode +
									' - ' +
									response.data.data[0].portfacilitygisiscode +
									' - ' +
									response.data.data[0].portfacilityname;
								nuevoObjeto['gisiscode'] =
									response.data.data[0].countrycode +
									response.data.data[0].portcode +
									' - ' +
									response.data.data[0].portfacilitygisiscode;
							}
						});
					}

					//CODE FOR securitylastcalls
					if (nuevaClave == 'casedisposalid') {
						const opts = {
							model: 'vlupcasedisposal',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'casedisposalid',
										op: 'eq',
										data: registrie[clave]
									}
								]
							}
						};
						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['casedisposal'] = response.data.data[0].description;
							}
						});
					}
					if (nuevaClave == 'healthstatusid') {
						const opts = {
							model: 'vluphealthstatus',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'healthstatusid',
										op: 'eq',
										data: registrie[clave]
									}
								]
							}
						};
						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['healthstatus'] = response.data.data[0].description;
							}
						});
					}
					if (nuevaClave == 'nationality') {
						const opts = {
							model: 'mcountries',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'countrycode',
										op: 'eq',
										data: registrie[clave]
									}
								]
							}
						};
						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['nationalitydesc'] = response.data.data[0].countrydesc;
							}
						});
					}
					if (nuevaClave == 'gendercode') {
						const opts = {
							model: 'mgender',
							filter: {
								groupOp: 'and',
								groups: [],
								rules: [
									{
										field: 'gendercode',
										op: 'eq',
										data: registrie[clave]
									}
								]
							}
						};
						await this.$puiRequests.postRequest('/puisearch', opts, (response) => {
							if (response.data.data[0]) {
								nuevoObjeto['gendercode'] = response.data.data[0].genderdescription;
							}
						});
					}

					nuevoObjeto[nuevaClave] = registrie[clave];
				}
				this.tableData.push({ ...nuevoObjeto });
				nuevoObjeto = {};
			}
			this.closeImport();
			this.setVisibleItems();
		}
	}
};
</script>
<style>
.nsw-editionable-selected-row {
	background-color: #2f6383 !important;
	font-weight: bold !important;
	color: white !important;
}
.nsw-editionable-page-count {
	width: 30px;
	display: inline-flex;
	justify-content: center;
}
.nsw-editionable-selected-row:hover {
	color: black !important;
}
</style>
