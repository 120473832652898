<template>
	<v-row class="pui-form-layout">
		<v-col xs12 lg12>
			<v-row dense>
				<!-- CALLSTARTDATE -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<pui-date-field
						:id="`callstartdate-lastcalls`"
						v-model="sheetData.callstartdate"
						:label="$t('lastcalls.callstartdate')"
						required
						toplabel
						@input="changeHour"
						:max="this.sheetData.callenddate || new Date()"
					></pui-date-field>
				</v-col>

				<!-- CALLENDDATE -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<pui-date-field
						:id="`callenddate-lastcalls`"
						v-model="sheetData.callenddate"
						:label="$t('lastcalls.callenddate')"
						required
						toplabel
						@input="changeHour2"
						:min="this.sheetData.callstartdate || new Date()"
						:max="new Date()"
					></pui-date-field>
				</v-col>
				<!-- COUNTRYCODE -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<pui-select
						:id="`countrycode-lastcalls`"
						:attach="`countrycode-lastcalls`"
						:label="$t('lastcalls.countrycode')"
						required
						toplabel
						clearable
						modelName="vlupmportswithfacilities"
						v-model="sheetData"
						reactive
						:itemsToSelect="itemsToSelectCountry"
						:modelFormMapping="{ countrycode: 'countrycode' }"
						:itemValue="['countrycode']"
						:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
						:order="{ countrycode: 'asc' }"
						:fixedFilter="filterCountryCode"
					></pui-select>
				</v-col>
				<!-- PORTCODE -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<pui-select
						:id="`portcode-lastcalls`"
						:attach="`portcode-lastcalls`"
						:label="$t('lastcalls.portcode')"
						required
						toplabel
						clearable
						modelName="vlupmportfacilities"
						v-model="sheetData"
						reactive
						:itemsToSelect="[{ portcode: sheetData.portcode }]"
						:modelFormMapping="{ portcode: 'portcode' }"
						:itemValue="['portcode']"
						:itemText="(item) => `${item.portcode} - ${item.portdescription}`"
						:order="{ portcode: 'asc' }"
						:fixedFilter="filterPortCode"
					></pui-select>
				</v-col>
			</v-row>
			<v-row dense>
				<!-- GISISCODE -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-show="sheetData.portfacilitycheck == 1">
					<pui-text-field
						:id="`portfacilitygisiscode-lastcalls`"
						v-model="sheetData.portfacilitygisiscode2"
						:label="$t('lastcalls.portfacilitygisiscode2')"
						:required="sheetData.portfacilitycheck == 1"
						toplabel
						maxlength="4"
						@input="updateGisiscode($event)"
					></pui-text-field>
				</v-col>
				<!-- FAMILYNAME -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-show="sheetData.portfacilitycheck == 1">
					<pui-text-field
						:id="`portfacilityname-lastcalls`"
						v-model="sheetData.portfacilityname2"
						:label="$t('lastcalls.portfacilityname2')"
						:required="sheetData.portfacilitycheck == 1"
						toplabel
						@input="updateFacilityName($event)"
					></pui-text-field>
				</v-col>
				<!-- PORTFACILITYGISISCODE -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" v-show="sheetData.portfacilitycheck != 1">
					<pui-select
						:id="`portfacilitygisiscode-lastcalls`"
						:attach="`portfacilitygisiscode-lastcalls`"
						:label="$t('lastcalls.portfacilitygisiscode')"
						:required="sheetData.portfacilitycheck != 1"
						toplabel
						clearable
						modelName="vlupmportfacilities"
						v-model="sheetData"
						reactive
						:itemsToSelect="[
							{ countrycode: this.sheetData.countrycode },
							{ portcode: this.sheetData.portcode },
							{ portfacilitygisiscode: this.sheetData.portfacilitygisiscode }
						]"
						:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode', portfacilitygisiscode: 'portfacilitygisiscode' }"
						:itemValue="['countrycode', 'portcode', 'portfacilitygisiscode']"
						:itemText="(item) => `${item.countrycode}${item.portcode}-${item.portfacilitygisiscode} - ${item.portfacilityname}`"
						:fixedFilter="filterPortFacility"
					></pui-select>
				</v-col>
				<!-- SECURITYLEVELCODE -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<pui-select
						:id="`securitylevelcode-lastcalls`"
						:attach="`securitylevelcode-lastcalls`"
						:label="$t('lastcalls.securitylevelcode')"
						required
						toplabel
						clearable
						modelName="vlupmsecuritylevels"
						v-model="sheetData"
						reactive
						:itemsToSelect="[{ securitylevelcode: this.sheetData.securitylevelcode }]"
						:modelFormMapping="{ securitylevelcode: 'securitylevelcode' }"
						:itemValue="['securitylevelcode']"
						:itemText="(item) => `${item.securityleveldescription}`"
					></pui-select>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<pui-checkbox
						toplabel
						:id="`portfacilitycheck-${modelName}`"
						:label="$t('lastcalls.portfacilitycheck')"
						v-model="sheetData.portfacilitycheck"
						true-value="1"
						false-value="0"
					></pui-checkbox>
				</v-col>
			</v-row>
			<v-row dense
				><!-- SECURITYMEASUEREIND -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<pui-select
						:id="`securitymeasuereind-lastcalls`"
						v-model="sheetData"
						:label="$t('lastcalls.securitymeasuereind')"
						:modelFormMapping="{ valor: 'securitymeasuereind' }"
						required
						reactive
						toplabel
						clearable
						modelName="vlupyesnoquestions"
						:itemsToSelect="[{ valor: this.sheetData.securitymeasuereind }]"
						:itemValue="['valor']"
						:itemText="(item) => `${item.opcion}`"
					></pui-select>
				</v-col>
				<!-- SECURITYMEASURECODE -->
				<v-col v-if="this.selectedsecuritymeasuereind == '1'" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<pui-select
						:id="`securitymeasurecode-lastcalls`"
						v-model="sheetData"
						:label="$t('lastcalls.securitymeasurecode')"
						toplabel
						modelName="vlupmsecuritymeasures"
						reactive
						required
						:modelFormMapping="{ securitymeasurecode: 'securitymeasurecode' }"
						:itemsToSelect="[{ securitymeasurecode: this.sheetData.securitymeasurecode }]"
						:itemValue="['securitymeasurecode']"
						:itemText="(item) => `${item.securitymeasurecode} - ${item.measuredescription}`"
					></pui-select>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'lastcallsformsheet',

	components: {},
	data() {
		return {
			sheetname: 'lastcallsformsheet',
			sheetData: {},
			portselected: '',
			countryselected: '',
			selectedsecuritymeasuereind: '',
			checkselected: 0,
			varGisiscode: '',
			varFacilityName: ''
		};
	},
	created() {
		this.sheetData = this.$store.getters.getlastcallsform;
	},
	mounted() {
		this.$puiEvents.$on('form-openinsert-' + this.sheetname, () => {
			this.sheetData = this.$store.getters.getlastcallsform;
		});
		this.$puiEvents.$on('form-submitted-' + this.sheetname, () => {
			console.log('a');
			if (this.checkselected == 1) {
				this.sheetData.portfacilityname = this.varFacilityName;
				this.sheetData.gisiscode = this.varGisiscode;
			}
			this.$puiEvents.$emit('form-datatransfer-' + this.sheetname, this.sheetData);
			this.$store.commit('setlastcallsform', {});
		});
		this.$puiEvents.$on('form-cancel-' + this.sheetname, () => {
			this.sheetData = {};
			this.$store.commit('setlastcallsform', {});
		});

		this.$puiEvents.$on('form-loadinfo-update-' + this.sheetname, (formData) => {
			this.sheetData = formData;
		});

		this.$puiEvents.$on('onPuiSelect_selectedItems-countrycode-lastcalls', (newVal) => {
			var lupaModel = newVal.model;
			this.countryselected = newVal.model.countrycode;
			this.sheetData.countrydesc = newVal.model.countrydesc;
			if (lupaModel !== null) {
				this.sheetData.countrycode = newVal.model.countrycode;
			} else {
				this.sheetData.countrycode = '';
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-portcode-lastcalls', (newVal) => {
			var lupaModel = newVal.model;
			this.portselected = newVal.model.portcode;
			this.sheetData.portdescription = newVal.model.portdescription;
			if (lupaModel !== null) {
				this.sheetData.portcode = newVal.model.portcode;
			} else {
				this.sheetData.portcode = '';
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-portfacilitygisiscode-lastcalls', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel !== null && this.sheetData.portfacilitycheck != 1) {
				this.sheetData.portfacilityname = lupaModel.portfacilityname;
				this.sheetData.gisiscode = lupaModel.gisiscode;
			} else {
				this.sheetData.portfacilityname = '';
				this.sheetData.gisiscode = '';
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-securitylevelcode-lastcalls', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel !== null) {
				this.sheetData.securitylevelcode = lupaModel.securitylevelcode;
			} else {
				this.sheetData.securitylevelcode = '';
			}
		});

		this.$puiEvents.$on('onPuiSelect_selectedItems-securitymeasurecode-lastcalls', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel !== null) {
				this.sheetData.securitymeasurecode = lupaModel.securitymeasurecode;
				this.sheetData.measuredescription = lupaModel.measuredescription;
			} else {
				this.sheetData.securitymeasurecode = '';
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-securitymeasuereind-lastcalls', (newVal) => {
			this.selectedsecuritymeasuereind = newVal.model.valor;
		});
		let filtro = this.$store.getters.getlastcallsform;
		this.countryselected = filtro.countrycode;
		this.portselected = filtro.portcode;
		this.sheetData = filtro;
	},
	watch: {
		'sheetData.securitymeasuereind'() {
			if (this.sheetData.securitymeasuereind === '0') {
				this.sheetData.securitymeasurecode = null;
				this.sheetData.securitymeasurecode = null;
			} else {
				//this.sheetData.securitymeasuereind = '1';
			}
		},
		'sheetData.securitymeasurecode'() {
			if (this.sheetData.securitymeasurecode != null) {
				this.sheetData.securitymeasuereind === '1';
			}
		},
		'sheetData.portfacilitycheck'() {
			if (this.sheetData.portfacilitycheck == 1) {
				this.checkselected = 1;
				this.sheetData.portfacilityname = '';
				this.sheetData.gisiscode = '';
			} else {
				this.checkselected = 0;
			}
		}
	},
	methods: {
		updateGisiscode(event) {
			this.varGisiscode = event;
		},
		updateFacilityName(event) {
			this.varFacilityName = event;
		},
		changeHour() {
			if (this.sheetData.callstartdate) {
				const originalDateTime = new Date(this.sheetData.callstartdate);
				const newDateTime = new Date(originalDateTime.getTime() + 3 * 60 * 60 * 1000);
				this.sheetData.callstartdate = newDateTime.toISOString();
			} else {
				this.sheetData.callstartdate = null;
			}
		},
		changeHour2() {
			if (this.sheetData.callenddate) {
				const originalDateTime = new Date(this.sheetData.callenddate);
				const newDateTime = new Date(originalDateTime.getTime() + 3 * 60 * 60 * 1000);
				this.sheetData.callenddate = newDateTime.toISOString();
			} else {
				this.sheetData.callenddate = null;
			}
		}
	},
	computed: {
		itemsToSelectCountry() {
			return [{ countrycode: this.sheetData.countrycode }];
		},
		filterCountryCode() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'portfacilitygisiscode', op: 'ne', data: null }]
					}
				]
			};
		},
		filterPortCode() {
			if (this.countryselected) {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'countrycode', op: 'eq', data: this.countryselected }]
						}
					]
				};
			} else {
				return null;
			}
		},
		filterPortFacility() {
			if (this.countryselected && this.portselected) {
				return {
					groups: [
						{
							groupOp: 'and',

							rules: [
								{ field: 'countrycode', op: 'eq', data: this.countryselected },
								{ field: 'portcode', op: 'eq', data: this.portselected }
							]
						}
					]
				};
			} else {
				return null;
			}
		}
	},
	destroyed() {
		this.$puiEvents.$off('form-submitted-' + this.sheetname);
		this.$puiEvents.$off('form-loadinfo-update-' + this.sheetname);
		this.$puiEvents.$off('onPuiSelect_selectedItems-countrycode-lastcalls');
		this.$puiEvents.$off('onPuiSelect_selectedItems-portcode-lastcalls');
		this.$puiEvents.$off('onPuiSelect_selectedItems-portfacilitygisiscode-lastcalls');
		this.$puiEvents.$off('onPuiSelect_selectedItems-securitylevelcode-lastcalls');
		this.$puiEvents.$off('onPuiSelect_selectedItems-securitymeasurecode-lastcalls');
		this.$puiEvents.$off('onPuiSelect_selectedItems-securitymeasuereind-lastcalls');
	}
};
</script>
<style>
#securitymeasuereind-lastcalls > div > div:nth-child(2) > div > div > div > div.v-input__slot {
	width: 150px;
}
#app > div.v-dialog__content.v-dialog__content--active > div > div > div.v-card__actions > div > div:nth-child(2) > button {
	margin-left: 75px !important;
}
</style>
