<template>
	<v-layout justify-space-between class="pui-form-footer-btns">
		<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>

		<div>
			<slot></slot>
			<pui-modal-dialog
				v-if="showSubmitDialog"
				:okText="$t('pui9.accept')"
				:titleText="$t('nswimoformfooterbtns.showSubmitDialog')"
				:messageText="$t('nswimoformfooterbtns.submitmessage')"
				:dialogName="`showSubmitDialog`"
				:cancelText="$t('form.cancel')"
			></pui-modal-dialog>
			<pui-modal-dialog
				v-if="showNewVersionDialog"
				:okText="$t('pui9.accept')"
				:titleText="$t('nswimoformfooterbtns.newVersionDialog')"
				:messageText="$t('nswimoformfooterbtns.newversionmessage')"
				:dialogName="`newVersionDialog`"
				:cancelText="$t('form.cancel')"
			></pui-modal-dialog>
			<v-btn
				v-if="!formDisabled && enableSaveAndUpdate"
				color="secondary"
				class="pui-form-footer-btns__desktop"
				@click.native="saveAndUpdate()"
				:disabled="saveDisabled"
				>{{ $t('nswimoformfooterbtns.next') }}</v-btn
			>
			<v-btn
				v-if="!formDisabled && enableSaveAndDraft"
				color="secondary"
				class="pui-form-footer-btns__desktop"
				@click.native="saveAndDraft()"
				:disabled="saveDisabled"
				>{{ $t('nswimoformfooterbtns.draft') }}</v-btn
			>
			<v-btn
				v-if="!formDisabled && enableNewVersion"
				color="secondary"
				class="pui-form-footer-btns__desktop"
				@click.native="openNewVersionDialog"
				:disabled="saveDisabled"
				>{{ $t('nswimoformfooterbtns.newVersion') }}</v-btn
			>
			<v-btn
				v-if="!formDisabled && enableSaveAndSubmit"
				color="secondary"
				class="pui-form-footer-btns__desktop"
				@click.native="openSubmitDialog"
				:disabled="saveDisabled"
				>{{ $t('nswimoformfooterbtns.submit') }}</v-btn
			>
		</div>
	</v-layout>
</template>

<script>
import { STATUS_DRAFT_ID, STATUS_SUBMITTED_ID, IMO_FAL_FORM_3_MODEL, IMO_FAL_FORM_1_MODEL } from '../../constants/imofalforms/ImoFalFormsConstants';
export default {
	name: 'NswImoFormFooterBtns',
	data() {
		return {
			isMobile: false,
			fab: false,
			top: false,
			bottom: true,
			left: false,
			right: true,
			direction: 'top',
			transition: 'slide-y-reverse-transition',
			showSubmitDialog: false,
			showNewVersionDialog: false,
			modelDependency: null
		};
	},
	props: {
		formDisabled: {
			type: Boolean,
			required: true
		},
		saveDisabled: {
			type: Boolean,
			default: false,
			required: false
		},
		saveAndUpdate: {
			type: Function,
			default: null,
			required: false
		},
		save: {
			type: Function,
			required: true
		},
		back: {
			type: Function,
			required: true
		},
		showNext: {
			type: Boolean,
			required: false
		},
		showDraft: {
			type: Boolean,
			required: false
		},
		showSubmit: {
			type: Boolean,
			required: false
		},
		showNewVersion: {
			type: Boolean,
			required: false
		},
		value: {
			type: Object,
			required: false
		},
		modelName: {
			type: String,
			required: false
		}
	},
	mounted() {
		this.subscribeToSubmit();
		this.subscribeToNewVersion();
	},
	beforeDestroy() {
		this.unsubscribeToSubmit();
		this.unsubscribeToNewVersion();
	},
	computed: {
		enableSaveAndUpdate() {
			return this.$store.state.form.showSaveAndUpdate && this.saveAndUpdate && this.showNext;
		},
		enableSaveAndDraft() {
			return this.showDraft;
		},
		enableSaveAndSubmit() {
			return this.showSubmit;
		},
		enableNewVersion() {
			return this.showNewVersion;
		}
	},
	created() {
		this.isMobile = this.$store.getters.isMobile;
	},
	methods: {
		openSubmitDialog() {
			this.showSubmitDialog = true;
		},
		openNewVersionDialog() {
			this.showNewVersionDialog = true;
		},
		saveAndDraft() {
			this.value.statusid = STATUS_DRAFT_ID;

			this.save(false);
		},
		async saveAndSubmit() {
			this.value.statusid = STATUS_SUBMITTED_ID;
			this.value.docstatusid = STATUS_SUBMITTED_ID;

			await this.save(false);
			this.showSubmitDialog = false;
			this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
		},
		async newVersion() {
			if (this.modelName === IMO_FAL_FORM_3_MODEL) {
				this.value.imofalformid3 = null;
			} else if (this.modelName === IMO_FAL_FORM_1_MODEL) {
				this.value.imofalformid1 = null;
			} else {
				this.value.imofalformid = null;
			}
			this.modelDependency = this.$store.getters.getModelByName(this.modelName);

			await this.$puiRequests.postRequest(
				this.modelDependency.url.insert,
				this.value,
				(response) => {
					this.saving = false;
					this.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.back();
				},
				() => {
					this.saving = false;
					this.onSaveError && this.onSaveError();
				}
			);
		},
		subscribeToSubmit() {
			this.$puiEvents.$on('pui-modalDialog-showSubmitDialog-ok', () => {
				this.saveAndSubmit();
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
				this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on('pui-modalDialog-showSubmitDialog-cancel', () => {
				this.showSubmitDialog = false;
			});
		},
		subscribeToNewVersion() {
			this.$puiEvents.$on('pui-modalDialog-newVersionDialog-ok', () => {
				this.newVersion();
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
				this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on('pui-modalDialog-newVersionDialog-cancel', () => {
				this.showNewVersionDialog = false;
			});
		},
		unsubscribeToSubmit() {
			this.$puiEvents.$off('pui-modalDialog-showSubmitDialog-ok');
			this.$puiEvents.$off('pui-modalDialog-showSubmitDialog-cancel');
		},
		unsubscribeToNewVersion() {
			this.$puiEvents.$off('pui-modalDialog-newVersionDialog-ok');
			this.$puiEvents.$off('pui-modalDialog-newVersionDialog-cancel');
		}
	}
};
</script>
